<!--
 * @Author: zhouxingtong
 * @Email: 495144492@qq.com
 * @Date: 2024-05-27 11:29:40
 * @LastEditors: zhouxingtong
 * @LastEditTime: 2024-07-06 16:00:35
 * @Description: 
-->
<template>
	<div style="height: 100vh">
		<iframe id="mapPage" width="100%" height="100%" frameborder="0" src="https://apis.map.qq.com/tools/locpicker?search=1&type=1&key=4DGBZ-Z273U-FKPVZ-474TQ-T3IJ7-PNBFT&referer=myapp" />
	</div>
</template>
<script>
export default {
	data() {
		return {}
	},
	mounted() {
		window.addEventListener(
			'message',
			function (event) {
				// 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
				const loc = event.data
				if (loc && loc.module === 'locationPicker') {
					// 防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
					console.log('location', loc)
				}
			},
			false
		)
	},
	methods: {}
}
</script>
